<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="handleSearch"
                    ref="filterLayoutRef">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="搜索" name="condition" class="rms-form-item">
              <a-input
                  v-model:value="formState.condition"
                  placeholder="Name/GGID"
                  enter-button
                  allowClear
                  autoComplete="off"
              />
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
<!--            <a-button type="primary" ghost v-auth="['system:availabilitStatus:updateAll']" @click="resetAllAvailability"> 处理Availability </a-button>-->
<!--            <a-button class="ml12" type="primary" ghost v-auth="['system:consultantStatus:updateAll']" @click="resetAllStatus"> 一键处理 </a-button>-->
            <a-button class="right" ghost type="primary" v-auth="['system:consultantStatus:updateAll']" @click="updateAllStatus"> 一键更新 </a-button>
          </div>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onActivated, onMounted } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common";
export default defineComponent({
  name: "Filter",
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();

    const formRef = ref();
    const roleNameOps = ref([]);
    const formState = reactive({
      condition: "",
    });
    const resetForm = () => {
      // formState.condition = "";
      formRef.value.resetFields();
      emit("search", formState);
    };

    const handleSearch = () => {
      emit("search", formState);
    };

    // const resetAllStatus = () => {
    //   $api.resetAllStatus().then(res => {
    //     message.success(res.data);
    //     handleSearch();
    //   });
    // };

    // const resetAllAvailability = () => {
    //   $api.resetAllAvailability().then(res => {
    //     message.success(res.msg);
    //     handleSearch();
    //   });
    // };
    const updateAllStatus = () => {
      $api.resetAllAvailability().then(res => {
        message.success(res.msg);
        handleSearch();
        emit("getStatus");
      });
    };
    onMounted(() => {
      handleSearch();
    });
    onActivated(() => {
      emit("resetTable", formState);
    });

    return {
      formState,
      roleNameOps,
      formRef,
      resetForm,
      handleSearch,
      //resetAllStatus,
      //resetAllAvailability,
      updateAllStatus,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  box-sizing: border-box;
}
</style>
