<template>
  <section class="userRoleList" ref="tableRef">
    <a-table
      :scroll="{
        x: tableWidth,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      class="left-table"
      :columns="userColumns"
      :data-source="staffInfoList"
      :pagination="pagination"
      row-key="id"
    >
      <template #action="{ record }">
        <ActionBtn v-bind="record" :btnList="btnList" @IdcardOutlined="infoClick(record)" @FileSyncOutlined="resetStatus(record)" />
      </template>
    </a-table>
    <div class="message1" v-if="dataBoxShow">
      人员状态：
      <span>更新数据数:</span>
      {{ empStatusData.updateCount ? empStatusData.updateCount : 0 }}
      <span>, 任务状态:</span>
      {{
        empStatusData.status == 1
            ? "进行中"
            : empStatusData.status == 2
                ? "已完成"
                : "已失败"
      }}
      <span>, 操作人:</span>
      {{ empStatusData.operator ? empStatusData.operator : "无" }}
      <span>, 操作时间:</span>
      {{ empStatusData.operationTime ? empStatusData.operationTime : "无" }}
    </div>
    <div class="message2" v-if="noDataShow">
      人员状态：
      <span>更新数据数:</span>
      {{ empStatusData.updateCount ? empStatusData.updateCount : 0 }}
      <span>, 任务状态:</span>
      {{
        empStatusData.status == 1
            ? "进行中"
            : empStatusData.status == 2
                ? "已完成"
                : "已失败"
      }}
      <span>, 操作人:</span>
      {{ empStatusData.operator ? empStatusData.operator : "无" }}
      <span>, 操作时间:</span>
      {{ empStatusData.operationTime ? empStatusData.operationTime : "无" }}
    </div>
    <EmpInfo
      v-if="empVisible"
      :is-showing-emp-info="empVisible"
      :get-id="userId"
      title="个人信息"
      :showwork-experience-col="false"
      :is-have-add="false"
      @close-emp-info="closeEmpInfo"
    />
  </section>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  nextTick,
  onMounted,
  onUnmounted,
} from "vue";
import { useTableHeight } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { message } from "ant-design-vue";
import EmpInfo from "@/components/EmpInfo";
import {cloneDeep} from "lodash";
import ActionBtn from "@/components/ActionBtn"

let queryCache = null;
const btnList = [
  {
    name: '个人信息',
    icon: 'IdcardOutlined',
    auth: ['system:empStatus:personalInformation']
  },
  {
    name: '更新',
    icon: 'FileSyncOutlined',
    auth: ['system:empStatus:availability']
  },
]
export default defineComponent({
  name: "index",
  components: {
    EmpInfo,
    ActionBtn
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const userColumns = [
      {
        title: "GGID",
        dataIndex: "ggid",
        ellipsis: true,
        width: 200,
      },
      {
        title: "姓名",
        dataIndex: "fullName",
        ellipsis: true,
      },
      {
        title: "Assignment状态",
        dataIndex: "assignmentStatus",
        ellipsis: true,
      },
      {
        title: "Task状态",
        dataIndex: "taskStatus",
        ellipsis: true,
      },
      {
        title: "人员状态",
        dataIndex: "status",
        ellipsis: true,
      },
      {
        title: "Availability",
        dataIndex: "availability",
        ellipsis: true,
        customRender: ({ text }) => text === "1" ? "Fully-bench": text === "2" ? "Unavailable" : "Partial Bench"
      },
      {
        title: "操作",
        dataIndex: "action",
        key: "action",
        slots: { customRender: "action" },
        width: 100,
        fixed: "right",
      },
    ];
    const staffInfoList = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        getStaffList(currentState);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        getStaffList(currentState);
      },
    });
    let currentState = cloneDeep(queryCache);
    const tableRef = ref();
    const tableWidth = ref(0);
    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = tableRef.value.offsetWidth - (13 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    //重置页码
    const resetCurrent = (queryData) => {
      queryCache = queryData ? queryData : {};
      if (queryCache.reset) {
        pagination.current = 1;
      }
    };

    // 获取staff-table数据
    const getStaffList = (queryData) => {
      queryCache = queryData ? queryData : {};
      currentState = cloneDeep(queryCache);
      const params = {
        condition: currentState.condition,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
      };
      $api.searchStatus(params).then((res) => {
        staffInfoList.value = res.data.resource;
        pagination.total = res.data.total;
        if (res.data.total == 0) {
          dataBoxShow.value = false;
          noDataShow.value = true;
        } else {
          dataBoxShow.value = true;
          noDataShow.value = false;
        }
      });
    };

    const resetStatus = (record) => {
      const params = {
        userId: record.id,
      };
      $api.resetStatus(params).then(() => {
        getStaffList(currentState);
        message.success("人员状态处理成功！");
        getEmpStatusData()
      });
    };

    const empVisible = ref(false);
    const userId = ref();
    const closeEmpInfo = () => {
      empVisible.value = false;
    };
    const infoClick = (record) => {
      userId.value = record.id;
      empVisible.value = true;
    };
    const noDataShow = ref(false);
    const dataBoxShow = ref("");
    const empStatusData = ref({})
    const getEmpStatusData = () => {
      $api.getEmpStatus().then(res => {
        empStatusData.value = res.data;
      })
    }

    onMounted(() => {
      resetCurrent();
      getStaffList();
      getEmpStatusData()
    });

    return {
      userColumns,
      pagination,
      staffInfoList,
      tableRef,
      tableWidth,
      tableHeight,
      resetCurrent,
      getStaffList,
      resetStatus,
      empVisible,
      userId,
      infoClick,
      closeEmpInfo,
      btnList,
      noDataShow,
      dataBoxShow,
      getEmpStatusData,
      empStatusData,
    };
  },
});
</script>

<style scoped lang="less">
.btn {
  margin: 20px;

  .confirm-btn {
    margin-right: 20px;
  }
}
.userRoleList {
  position: relative;
  .message1 {
    position: absolute;
    bottom: 20px;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    span {
      color: #b3b3b3;
    }
  }
  .message2 {
    position: absolute;
    margin-top: 15px;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    span {
      color: #b3b3b3;
    }
  }
}
</style>
