<template>
  <!--  <div>人员角色关系管理</div>-->
  <section class="app-container">
    <Filter @search="selectSearch" @resetTable="handleResetTable" @getStatus="getStatus"></Filter>
    <EmpList ref="userRoleListRef"></EmpList>
  </section>
</template>

<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import EmpList from "./components/EmpList.vue";

export default defineComponent({
  name: "maintainEmpStatus",
  components: {
    Filter,
    EmpList,
  },
  setup() {
    const userRoleListRef = ref([]);
    const selectSearch = (data) => {
      data.reset = true;
      userRoleListRef.value.resetCurrent(data);
      userRoleListRef.value.getStaffList(data);
    };
    const handleResetTable = (data) => {
      userRoleListRef.value.getStaffList(data);
    };
    const getStatus = () => {
      userRoleListRef.value.getEmpStatusData();
    }
    return {
      userRoleListRef,
      selectSearch,
      handleResetTable,
      getStatus,
    };
  },
});
</script>

<style scoped>
.app-container {
  height: 100%;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 8px;
}
</style>
